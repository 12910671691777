import { Modal } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';
import PortletBody from '../../../../app/components/portlet/PortletBody';
import CompanyListingTable from '../../listing/CompanyListingTable';
import SearchIcon from '../../../../app/components/svgIcons/SearchIcon';
import StyledIconInput from '../../../../app/components/styledComponents/StyledIconInput';
import styled from 'styled-components';
import SortIcon from '../../../../app/components/svgIcons/SortIcon';
import { StyledLabel } from '../../../Campaigns/CampaignDetails';
import theme from '../../../../utils/themes';
import StyledFilterTabs from '../../../../app/components/styledComponents/StyledFilterTabs';
import StyledToolTip from '../../../../app/components/styledComponents/StyledToolTip';
import PauseIcon from '../../../../app/components/svgIcons/PauseIcon';
import StyledButton from '../../../../app/components/styledComponents/StyledButton';
import StyledRadioButton from '../../../../app/components/radio/StyledRadioButton';
import { Wrap } from '../../../../assets/styledComponents/Global';
import { sortData } from '../../../Products/assets/utills';
import { StyledScrollView } from '../../../../app/components/styledComponents/ScrollView';
import { useGetPurchaseHistoryQuery } from '../../companyApiSlice';
import { useParams } from 'react-router-dom';
import PaginationPerPage from '../../../../app/components/pagination/PaginationPerPage';
import moment from 'moment';
import { getCurrencySign } from '../../../../utils/helper';
import StyledCheckbox from '../../../../app/components/checkbox/StyledCheckbox';

const CompanyTableModal = ({ show, onHide }) => {
  const { compId } = useParams();
  const [isOpen, setIsopen] = useState(false);
  const [sortFilter, setSortFilter] = useState(false);

  const [selectedColumns, setSelectedColumns] = useState(false);
  const selectColumnRef = useRef(null);
  const sortFilterRef = useRef(null);

  // const [search, setSearch] = useState('');

  const initialFilters = {
    jobs: [],
    perPage: 5,
    page: 1,
    sortedColumn: { path: 'createdAt', order: 'DESC' },
    query: '',
  };
  // const [tabs, setTabs] = useState([
  //   {
  //     id: 1,
  //     title: 'All Companies',
  //     active: true,
  //     status: STATUSES.NULL,
  //   },
  //   {
  //     id: 2,
  //     title: 'Standard',
  //     active: false,
  //     status: STATUSES.DRAFT,
  //   },
  //   {
  //     id: 3,
  //     title: 'Agency',
  //     active: false,
  //     status: STATUSES.CLOSED,
  //   },
  // ]);
  const [listing, setListing] = useState({ ...initialFilters });

  const { data: purchaseHistory, refetch } = useGetPurchaseHistoryQuery({
    params: {
      perPage: listing.perPage,
      page: listing.page,
      query: listing.query && listing.query,
      sort: listing.sortedColumn.path,
      sortDir: listing.sortedColumn.order,
    },
    id: compId,
  });

  const [timer, setSearchTimer] = useState(null);
  const handleSearch = (evt) => {
    clearTimeout(timer);
    const query = evt.target.value;
    // setSearch(query);
    const newTimer = setTimeout(() => {
      setListing({ ...listing, page: 1, query: query });
    }, 2000);
    setSearchTimer(newTimer);
  };
  const handleSort = (sort) => {
    const sortBlacklist = ['hired', 'statusId', 'account.firstName'];
    if (!sortBlacklist.includes(sort.path)) {
      setListing((prevState) => ({
        ...prevState,
        sortedColumn: { ...sort },
        page: 1,
      }));
    }
  };
  const handlePerPageChange = (perPage) => {
    setListing((prevState) => ({
      ...prevState,
      perPage: perPage.target.innerText,
      page: 1,
    }));
  };

  const handlePageChange = ({ selected }) => {
    setListing((prevState) => ({
      ...prevState,
      page: selected + 1,
    }));
  };
  const [newColumn, setNewColumn] = useState([]);
  const [column, setColumn] = useState([
    {
      id: 1,
      label: 'Transaction',
      isChecked: true,
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader: 'text-left align-middle cursor-pointer ps-4 py-3',
      content: (purchaseHistory) =>
        purchaseHistory?.walletTransactions?.map((item, index) => (
          <div className="d-flex flex-column" key={index}>
            <StyledLabel color={theme.textColor}>
              {item?.wallet?.invoiceId}
            </StyledLabel>
            <StyledLabel color={theme.textColor} weight="500px">
              {moment(purchaseHistory?.createdAt).format('Do MMM YYYY,HH:mm')}
            </StyledLabel>
          </div>
        )),
    },
    {
      id: 2,
      isChecked: true,
      label: 'Type',
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader: 'text-left align-middle cursor-pointer ps-4 py-3',
      content: (purchaseHistory) => (
        <StyledLabel color={theme.textColor} weight="500px">
          {purchaseHistory?.transactionType}
        </StyledLabel>
      ),
    },
    {
      id: 3,
      isChecked: true,
      label: 'Currency',
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader: 'text-left align-middle cursor-pointer ps-4 py-3',
      content: (purchaseHistory) =>
        purchaseHistory?.walletTransactions?.map((item, index) => (
          <StyledLabel color={theme.textColor} weight="500px" key={index}>
            {item?.wallet?.currencyType}
          </StyledLabel>
        )),
    },
    {
      id: 4,
      isChecked: true,
      label: 'Amount',
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader: 'text-left align-middle cursor-pointer ps-4 py-3',
      content: (purchaseHistory) =>
        purchaseHistory?.walletTransactions?.map((item, index) => (
          <StyledLabel color={theme.textColor} weight="500px" key={index}>
            {item?.amount}
          </StyledLabel>
        )),
    },
    {
      id: 5,
      isChecked: true,
      label: 'Cost/unit',
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader: 'text-left align-middle cursor-pointer ps-4 py-3',
      content: (purchaseHistory) =>
        purchaseHistory?.walletTransactions?.map((item) => (
          <Wrap className="d-flex align-items-center gap-1">
            {item?.wallet?.occyCredits && (
              <StyledLabel weight="500px">
                {getCurrencySign(item?.wallet?.currencyType)}
              </StyledLabel>
            )}
            <StyledLabel color={theme.textColor} weight="500px">
              {item?.wallet?.occyCredits
                ? (
                    item?.wallet?.amountAdded / item?.wallet?.occyCredits
                  ).toFixed(2)
                : '-'}
            </StyledLabel>
          </Wrap>
        )),
    },
    {
      id: 6,
      isChecked: true,
      label: 'Price',
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader: 'text-left align-middle cursor-pointer ps-4 py-3',
      content: (purchaseHistory) =>
        purchaseHistory?.walletTransactions?.map((item, index) => (
          <Wrap className="d-flex align-items-center gap-1" key={index}>
            <StyledLabel weight="500px">
              {getCurrencySign(item?.wallet?.currencyType)}
            </StyledLabel>

            <StyledLabel color={theme.textColor} weight="500px">
              {purchaseHistory?.fiatVal}
            </StyledLabel>
          </Wrap>
        )),
    },
    {
      id: 7,
      isChecked: true,
      label: 'Admin Comment',
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader: 'text-left align-middle cursor-pointer ps-4 py-3 w-25',
      content: (purchaseHistory) => (
        <StyledLabel
          color={theme.textColor}
          weight="500px"
          className=" text-wrap"
        >
          {purchaseHistory?.comments}
        </StyledLabel>
      ),
    },
  ]);

  const handleCHeck = (item) => {
    const tempColumn = [...column];
    const newItemIndex = column.findIndex((t) => t.id === item.id);
    tempColumn[newItemIndex].isChecked = !tempColumn[newItemIndex].isChecked;
    setColumn(tempColumn);
  };

  const handleSelectedColumn = () => {
    const newArr = [];
    column.map((item) => item.isChecked && newArr.push(item));
    setNewColumn(newArr);
    setSelectedColumns(false);
  };

  useEffect(() => {
    handleSelectedColumn();
  }, []);

  let ToggleFilterbar = (item) => {
    setIsopen(item === 'filter' ? !isOpen : false);
    setSortFilter(item === 'sort' ? !sortFilter : false);
    setSelectedColumns(item === 'selected' ? !selectedColumns : false);
  };

  const handleSortBy = (item) => {
    const prevFilters = { ...listing };
    prevFilters.sortedColumn = item;
    setListing(prevFilters);
  };

  useEffect(() => {
    if (show) refetch();
  }, [show]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSortFilter(false);
          setSelectedColumns(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(
    (sortFilter && sortFilterRef) || (selectedColumns && selectColumnRef),
  );

  return (
    <Modal show={show} centered onHide={onHide} size={'xl'}>
      <Modal.Header
        closeButton
        className="border-bottom-0 fs-5 pb-0 align-items-center mb-3 ps-2"
      >
        <Modal.Title as="h4" className="flex-grow-1 p-2 fs-2 flex-center">
          Transaction History
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex  flex-column bg-body rounded ">
        <StyledScrollView>
          <PortletBody className="p-3 w-100 ">
            <>
              <Wrap className="d-flex justify-content-between align-items-center mb-3 position-relative">
                <StyledIconInput
                  icon={<SearchIcon />}
                  className="mw-50"
                  $maxWidth="55%"
                  // value={search} // setting a value manually here triggers react warnings
                  onChange={handleSearch}
                  placeholder="Search Transactions"
                />
                <StyledFilterTabs padding="5px">
                  <div className="btn-group " role="group">
                    <button
                      className="btn"
                      id="sort"
                      onClick={() => ToggleFilterbar('sort')}
                    >
                      <StyledToolTip title="Sort by" childrenClass={'ms-0'}>
                        <SortIcon size={15} />
                      </StyledToolTip>
                    </button>

                    <button
                      className="btn"
                      onClick={() => ToggleFilterbar('selected')}
                    >
                      <PauseIcon size={15} />
                    </button>
                  </div>
                </StyledFilterTabs>
                <div
                  ref={selectColumnRef}
                  className="d-flex rounded flex-column"
                >
                  {selectedColumns && (
                    <DropdownMenu className="ms-auto shadow ">
                      <div className="d-flex justify-content-between flex-column mb-3 ps-4">
                        <h5 className="mb-0 ">Column Modifier</h5>
                        <p className="mb-0 ">Modify column view</p>
                      </div>
                      {column.map((item, index) => {
                        return (
                          <StyledBgEffect
                            className="d-flex align-items-center py-2 ps-4 cursor-pointer"
                            key={`cl-${index}`}
                          >
                            <StyledCheckbox
                              size="14"
                              value={item.isChecked}
                              onChange={() => handleCHeck(item)}
                            />
                            <h6 className="ms-2 mb-0">{item.label}</h6>
                          </StyledBgEffect>
                        );
                      })}
                      <div className="mx-4 mt-2">
                        <StyledButton
                          onClick={handleSelectedColumn}
                          className="w-100 p-1"
                        >
                          Apply
                        </StyledButton>
                      </div>
                    </DropdownMenu>
                  )}
                </div>
                <div ref={sortFilterRef}>
                  {sortFilter && (
                    <DropdownMenu className="ms-auto shadow ">
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5 className="mb-0 ps-4">Sort by</h5>
                      </div>
                      {sortData?.map((item, index) => {
                        return (
                          <StyledBgEffect
                            className={`${
                              item.value === 'nearest' ? 'feature-pending' : ''
                            } d-flex align-items-center py-2 ps-4 `}
                            key={`sort-${item.value}-index`}
                          >
                            <StyledRadioButton
                              size={15}
                              value={
                                listing.sortedColumn.path ===
                                  item.sortvalue.path &&
                                listing.sortedColumn.order ===
                                  item.sortvalue.order
                              }
                              label={item.label}
                              className="fw-bold"
                              onChange={() => handleSortBy(item.sortvalue)}
                            />
                          </StyledBgEffect>
                        );
                      })}
                    </DropdownMenu>
                  )}
                </div>
              </Wrap>
              <CompanyListingTable
                companies={purchaseHistory?.transactions ?? []}
                sortColumn={listing?.sortedColumn}
                onSort={handleSort}
                filterTable={newColumn}
              />
              <PaginationPerPage
                perPage={purchaseHistory?.perPage || 0}
                pageNo={purchaseHistory?.currentPage || 1}
                onPerPageChange={handlePerPageChange}
                totalCount={purchaseHistory?.totalCount || 0}
                onPageChange={handlePageChange}
              />
            </>
          </PortletBody>
        </StyledScrollView>
      </Modal.Body>
    </Modal>
  );
};

const StyledBgEffect = styled.div`
  &:hover {
    background-color: ${({ theme }) => theme.bodyBg};
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  min-width: 15rem;
  width: fit-content;
  padding: 0.5rem 0;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
`;

export default CompanyTableModal;
