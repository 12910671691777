import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Portlet from '../../app/components/portlet/Portlet';
import PortletBody from '../../app/components/portlet/PortletBody';
import theme from '../../utils/themes';
import { StyledLabel } from './CampaignDetails';
import {
  useCloseCampaignMutation,
  useGetCampaignOccurrenceQuery,
  useGetCampaignPostingDetailsQuery,
} from './CampainApiSlice';
import moment from 'moment';
import DotIcon from '../../app/components/svgIcons/DotIcon';
import { isArray } from 'lodash';
import EyeIcon from '../../app/components/svgIcons/EyeIcon';
import CircledResumeIcon from '../../app/components/svgIcons/CircledResumeIcon';
import StyledToolTip from '../../app/components/styledComponents/StyledToolTip';
import CampaignPostingDetails from './CampaignPostingDetails';
import { toast } from 'react-toastify';
import StyledIconInput from '../../app/components/styledComponents/StyledIconInput';
import SearchIcon from '../../app/components/svgIcons/SearchIcon';
import StyledFilterTabs from '../../app/components/styledComponents/StyledFilterTabs';
import SortIcon from '../../app/components/svgIcons/SortIcon';
import { STATUSES } from '../../utils/Statuses';
import PortletHeader from '../../app/components/portlet/PortletHeader';
import StyledColoredTag from '../../app/components/styledComponents/StyledColoredTag';
import StyledRadioButton from '../../app/components/radio/StyledRadioButton';
import styled from 'styled-components';
import { PostingSortItem } from './CampaignsUtils';
// import { useGetAllCompanySettingsQuery } from '../company/companyApiSlice';

const CampaignOccurrence = ({ show, onHide, campaign, id, refetch }) => {
  const [closeCampaign] = useCloseCampaignMutation();
  const [sortFilter, setSortFilter] = useState(false);
  const [sorted, setSorted] = useState(null);
  const [postingDetail, setPostingDetail] = useState();
  const [filteredDetail, setFilteredDetail] = useState();
  const sortFilterRef = useRef(null);
  const [postingRecord, setPostingRecord] = useState();
  const { data : campaignPosting, refetch : campaginRefetch } = useGetCampaignPostingDetailsQuery({
    postingIds: postingRecord?.ids,
  },{
    skip : !postingRecord?.ids,
    refetchOnMountOrArgChange:true,
  });
  const handleCloseJobCampaign = async () => {
    const { data } = await closeCampaign({ id: campaign.id });
    if (data?.message) {
      toast.success(data?.message || `Campaign closed successfully!`);
      if (refetch) {
        refetch();
      }
      onHide();
    }
  };
  let ToggleFilterbar = (item) => {
    setSortFilter(item === 'sort' ? !sortFilter : false);
  };
  const handleSortBy = (item) => {
    const sortedData = [...filteredDetail]; 
    sortedData.sort((a, b) => {
      const dateA = new Date(a?.createdAt);
      const dateB = new Date(b?.createdAt);
      if (item.dir === 'ASC') {
        return dateA - dateB; 
      } else {
        return dateB - dateA;
      }
    });
    setSorted(item.match);
    setPostingDetail(sortedData);
  };
  const [filters, setFilters] = useState('');
  const [tabs, setTabs] = useState([
    {
      id: 0,
      title: 'All',
      active: true,
      status: STATUSES.NULL,
    },
    {
      id: 2,
      title: 'Live',
      active: false,
      status: STATUSES.LIVE,
    },
    {
      id: 26,
      title: 'Review',
      active: false,
      status: STATUSES.LIVE,
    },
    {
      id: 1,
      title: 'Pending',
      active: false,
      status: STATUSES.PENDING,
    },
    {
      id: 27,
      title: 'Being Posted',
      active: false,
      status: STATUSES.BEING_POSTED,
    },
    {
      id: 28,
      title: 'Posted',
      active: false,
      status: STATUSES.POSTED,
    },
    {
      id: 36,
      title: 'Canceled',
      active: false,
      status: STATUSES.CANCELED,
    }
  ]);
  const onChangeTabs = (tab) => {
    const filteredData = tab === 0 ? filteredDetail : filteredDetail?.filter((item) => item?.statusId === tab);
    setTabs((prevState) =>
      prevState.map((p) => {
        p.id === tab &&
          setFilters({
            ...filters,
            statusId: p.status === 0 ? undefined : p.status,
          });
        return p.id === tab
          ? {
              ...p,
              active: true,
            }
          : {
              ...p,
              active: false,
            };
      }),
    );
    setPostingDetail(filteredData)
  };

  const { data: details } = useGetCampaignOccurrenceQuery({ id: id });
  
   function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref?.current && !ref?.current.contains(event?.target)) {
          setSortFilter(false);
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(
    (sortFilter && sortFilterRef) 
  );

  useEffect(() => {
    const newArray = [];
    const productMap = new Map(); 
  
    if (Array.isArray(details?.jobPostings) && details?.jobPostings?.length > 0) {
      const createdAtMap = new Map();
  
      details?.jobPostings?.forEach((job) => {
        const createdAt = job?.createdAt ? job?.createdAt.split("T")[0] : "Unknown"; 
  
        if (!createdAtMap?.has(createdAt)) {
          createdAtMap?.set(createdAt, {
            ids: [],
            credit: 0,
            fiat: 0,
            statusIds: new Set(),
            count: 0,
          });
        }
  
        createdAtMap?.get(createdAt)?.ids.push(job?.id);
  
        // Check jobPostingTransactions and update credit or fiat based on currencyType
        if (job?.jobPostingTransactions && job?.jobPostingTransactions?.length > 0) {
          job?.jobPostingTransactions?.forEach((transaction) => {
            if (transaction?.transaction?.currencyType === 'OCCY_CREDITS') {
              createdAtMap.get(createdAt).credit += transaction?.transaction?.fiatVal;
            } else if (transaction?.transaction?.currencyType === 'FIAT') {
              createdAtMap.get(createdAt).fiat += transaction?.transaction?.fiatVal;
            }
          });
        }
  
        createdAtMap.get(createdAt).statusIds?.add(job?.statusId);
        createdAtMap.get(createdAt).count++;
  
        const productTitle = job?.product?.title;
        if (!productMap?.has(createdAt)) {
          productMap?.set(createdAt, [productTitle]);
        } else {
          productMap?.get(createdAt)?.push(productTitle);
        }
      });
  
      createdAtMap?.forEach(({ ids, credit, fiat, statusIds, count }, createdAt) => {
        let statusId = 0;
  
        if (statusIds?.size === 1) {
          const uniqueStatusId = statusIds?.values()?.next()?.value;
          statusId = uniqueStatusId;
        } else if (statusIds?.has(28) && !statusIds?.has(26) && !statusIds?.has(36)) {
          statusId = 2;
        } else if (statusIds?.has(26) && !statusIds?.has(28) && !statusIds?.has(36)) {
          statusId = 1;
        } else if (statusIds?.has(36) && !statusIds?.has(28) && !statusIds?.has(26)) {
          statusId = 21;
        } else if (statusIds?.has(28) || statusIds?.has(26)) {
          statusId = 1;
        }
  
        // Create an object for the current createdAt
        const currentObject = {
          createdAt,
          ids: ids.join(','),
          credit,
          fiat,
          statusId,
          count,
        };
  
        // Include the 'product' variable in the object
        if (productMap?.has(createdAt)) {
          currentObject.product = productMap?.get(createdAt)?.map((title) => ({ title }));
        }
        newArray.push(currentObject);
      });
    } 
    setPostingDetail(newArray);
    setFilteredDetail(newArray);
  }, [details]);
  
  
  const getStatusKey=(statusId)=> {
    for (const key in STATUSES) {
      if (STATUSES[key] === statusId) {
        return key;
      }
    }
    return null; // Return null if no match is found
  }

  const handleSearch = (evt) => {
    const query = evt.target.value;
    setTimeout(() => {
      const filteredPostingDetail = filteredDetail?.filter((item) => {
        if (!query) {
          return true;
        }
        return item?.product?.some((product) =>
          product?.title?.toLowerCase().includes(query.toLowerCase())
        );
      });
      setPostingDetail(filteredPostingDetail);
    }, 2000);
  };
  
  // const commpanyId = campaignPosting && campaignPosting.length > 0 ? campaignPosting[0]?.jobPostings?.companyId : null;
  // const initialFilters = {
  //   perPage: 5,
  //   page: 1,
  //   query: "MULTI_POSTER",
  // };
  // const [listing, setListing] = useState({ ...initialFilters });
  // const { data: settingRes, refetch: refetchPoster } = useGetAllCompanySettingsQuery(
  //   {
  //     params: {
  //       perPage: listing.perPage,
  //       page: listing.page,
  //       query: listing.query && listing.query,
  //     },
  //     id: commpanyId,
  //   },
  //   {
  //     skip: !commpanyId,
  //   }

  // );

  const companySetting =  "true";
  // useEffect(() => {
  //   refetchPoster();
  // }, [commpanyId]);



  return (
    <Modal show={show} centered onHide={onHide} size={'lg'}>
      <Modal.Header
        closeButton
        className="d-flex border-bottom-0 pb-0 align-items-baseline ps-4"
      >
        <Portlet>
          <PortletHeader
            tabs={tabs}
            title={campaign?.campaignName || ''}
            onChangeTabs={onChangeTabs}
            containerClassName="p-0"
            actions={<></>}
          />
        </Portlet>
      </Modal.Header>
      <Modal.Body className="d-flex  flex-column bg-body rounded p-0 mt-0">
        <Portlet className="bg-body m-3">
          <PortletBody className="rounded">
            <div className="d-flex justify-content-between align-items-center ">
              <div>
                <StyledLabel
                  weight="600"
                  size="16px"
                  color={theme.headingColor}
                >
                  All Postings
                </StyledLabel>
              </div>
              <div className="d-flex flex-grow-1 align-items-center ">
                <StyledIconInput
                  icon={<SearchIcon />}
                  className="mw-50 flex-grow-1"
                  $maxWidth="60%"
                  $marginLeft="3px"
                  $floatRight="right"
                  onChange={handleSearch}
                  // loading={isFetching}
                  placeholder="Search by Channel or Broadbean ID"
                />
                <StyledFilterTabs>
                  <div className="btn-group " role="group">
                    <button
                      className="btn p-1 ms-2"
                    // onClick={() => setFilters(!filters)}
                    onClick={() => ToggleFilterbar('sort')}
                    >
                      <SortIcon size={15} className="mx-1" /> Sort
                    </button>
                  </div>
                </StyledFilterTabs>
                <div
                    ref={sortFilterRef}
                    className="m-0 position-relative d-flex rounded flex-column"
                  >
                    {sortFilter && (
                      <StyledFilterBar
                        width="290px"
                        className="bg-white py-3 align-items-center justify-content-center"
                      >
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <h5 className="mb-0 ps-4">Sort by</h5>
                        </div>
                        {PostingSortItem?.map((item, index) => {
                          return (
                            <StyledBgEffect
                              className={`${item.value === 'nearest'
                                ? 'feature-pending'
                                : ''
                                } d-flex align-items-center py-2 ps-4 `}
                              key={`sort-${item.match}-postadvert`}
                            >
                              <StyledRadioButton
                                size={15}
                                dotSize={7}
                                value={
                                  sorted === item.match ||
                                  (!sorted && index === 0 && true)
                                }
                                label={item.label}
                                className="fw-bold"
                                onChange={() => handleSortBy(item)}
                              />
                            </StyledBgEffect>
                          );
                        })}
                      </StyledFilterBar>
                    )}
                  </div>
              </div>
            </div>
            <div className="p-0">
              {isArray(postingDetail) && postingDetail?.length > 0 ? (
                postingDetail?.map((post, postIndex) => (
                    <div className="border border-1 border-muted rounded p-2 my-3"  key={`postings-${postIndex}`}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex flex-column ">
                          <div className="d-flex ">
                            <StyledLabel
                              weight="600"
                              size="14px"
                              color={theme.textColor}
                              className="mb-1"
                            >
                              {post?.createdAt ? moment(post?.createdAt)?.format('DD MMM YYYY') : ''}
                            </StyledLabel>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <StyledColoredTag className='m-0' color={post?.statusId === STATUSES.PENDING ? theme.darkYellow :
                             post?.statusId === STATUSES.LIVE ? theme.green :
                              post?.statusId === STATUSES.CANCELED ? theme.darkRed 
                              :''}
                              backgroundColor={post?.statusId === STATUSES.PENDING ? theme.lightYellow :
                               post?.statusId === STATUSES.LIVE ? theme.skygreen :
                                post?.statusId === STATUSES.CANCELED ? theme.evaluationBannerColor :
                                 post?.statusId === STATUSES.REVIEW ? theme.primaryLight :''}>
                                 {getStatusKey(post?.statusId)}
                            </StyledColoredTag>
                            <DotIcon className="text-gray" />
                            <StyledLabel
                              weight="500"
                              size="12px"
                              color={theme.lightBoldBlack}
                            >
                              {post?.count || 0}{' '}
                              {`channel${post?.count > 1 ? 's' : ''}`}
                </StyledLabel>
                            <DotIcon className="text-gray" />
                            <StyledLabel weight="500" size="12px" color={theme.lightBoldBlack}>

                            {`£ ${post?.credit}`}
                </StyledLabel>
                          </div>
                        </div>
                        <div className="text-primary d-flex gap-2 align-items-center me-2">
                          <StyledToolTip
                            title="View details"
                            childrenClass={'ms-0 cursor-pointer'}
                          >
                            <span
                              onClick={() => {
                                setPostingRecord(post);
                                // onHide();
                              }}
                            >
                              <EyeIcon size={22} />
                            </span>
                          </StyledToolTip>

                          <StyledToolTip
                            title="Close campaign"
                            childrenClass={'ms-0 cursor-pointer'}
                          >
                            <span onClick={handleCloseJobCampaign}>
                              <CircledResumeIcon />
                            </span>
                          </StyledToolTip>
                        </div>
                      </div>
                    </div>
                ))
              ) : (
                <div className="my-2 ">No posting record found!</div>
              )}
            </div>
          </PortletBody>
          {postingRecord  &&(
            <CampaignPostingDetails
              details={postingRecord}
              show={!!postingRecord}
              onHide={() => setPostingRecord(null)}
              title={campaign?.campaignName || ''}
              campaign={campaign}
              data = {campaignPosting}
              campaginRefetch={campaginRefetch}
              companySetting={companySetting}
              // ids={postingRecord?.ids || ''}
            />
          )}
        </Portlet>
      </Modal.Body>
    </Modal>
  );
};

const StyledFilterBar = styled.div`
  width: ${(props) => props?.width ?? '229px'};
  // height: ${(props) => (props?.variant ? '91vh !important' : 'none')};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  position: ${(props) => (props?.variant ? 'fixed' : 'absolute')};
  margin-top: ${(props) => (!props?.variant ? '35px' : 'none')};
  top: ${(props) => (props.variant ? '90px' : 'none')};
  right: 0;
  bottom: ${(props) => (props?.variant ? '0' : 'none')};
  z-index: 2;
  border-radius: ${(props) => (props?.variant ? '8px 0 0 0' : '14px')};
  transition: 0.5s;
  padding-bottom: 28px;

  .sd-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    color: ${({ theme }) => theme.textColor};
  }

  .sd-body {
    padding: 20px;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: auto;
  }

  .sd-body ul {
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    padding: 0;
  }

  .sd-body ul li {
    list-style: none;
    margin-bottom: 8px;
  }
`;
const StyledBgEffect = styled.div`
  &:hover {
    background-color: ${({ theme }) => theme.bodyBg};
  }
`;
export default CampaignOccurrence;
