import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import PaginationPerPage from '../../../app/components/pagination/PaginationPerPage';
import Portlet from '../../../app/components/portlet/Portlet';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import StyledIconInput from '../../../app/components/styledComponents/StyledIconInput';
import EditIcon from '../../../app/components/svgIcons/EditIcon';
import FilterIcon from '../../../app/components/svgIcons/FilterIcon';
import SearchIcon from '../../../app/components/svgIcons/SearchIcon';
import SortIcon from '../../../app/components/svgIcons/SortIcon';
import TrashIcon from '../../../app/components/svgIcons/TrashIcon';
import { getStatusFriendly } from '../../../utils/helper';
// import { uuidv4 } from '../../../utils/helper';
import AddUserModal from '../components/AddUserModal';
import UsersListingTable from './UsersListingTable';
import { StyledFilterBar } from '../../../app/components/styledComponents/StyledFilterBar';
import StyledRadioButton from '../../../app/components/radio/StyledRadioButton';
import StyledColoredTag from '../../../app/components/styledComponents/StyledColoredTag';
import OnlineIcon from '../../../app/components/svgIcons/OnlineIcon';
import TableSkeleton from '../../../assets/skeletons/tableSkeleton';
import { useDeleteUserMutation, useGetAllUsersQuery } from '../userApiSlice';
import { toast } from 'react-toastify';

const UserListingContainer = () => {
  const [deleteUser] = useDeleteUserMutation();
  const [sortFilter, setSortFilter] = useState(false);
  const [isOpen, setIsopen] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [filters, setFilters] = useState('');
  const [timer, setSearchTimer] = useState(null);
  const [editData, setEditData] = useState(null);
  const { compId } = useParams();
  const initialFilters = {
    perPage: 5,
    page: 1,
    sortedColumn: { path: 'firstName', order: 'ASC' },
    query: '',
  };
  const [listing, setListing] = useState({ ...initialFilters });



  let ToggleFilterbar = (item) => {
    setIsopen(item === 'filter' ? !isOpen : false);
    setSortFilter(item === 'sort' ? !sortFilter : false);
  };

  const handleSortBy = (item) => {
    const prevFilters = { ...filters };
    prevFilters.sort = item.value;
    prevFilters.sortDir = item.dir;
    setFilters({ ...prevFilters });
  };

  const sortFilters = [
    {
      id: 1,
      label: 'Added on (Latest First)',
      value: 'applied-latest-to-oldest',
      dir: 'DESC',
    },
    {
      id: 2,
      label: 'Applied on (Oldest First)',
      value: 'applied-oldest-to-latest',
      dir: 'ASC',
    },
    {
      id: 3,
      label: 'Ratings (Highest First)',
      value: 'userRating',
      dir: 'DESC',
    },
    {
      id: 4,
      label: 'Lastest First (Lowest First)',
      value: 'last_activity',
      dir: 'ASC',
    },
    {
      id: 5,
      label: 'Location (Nearest First)',
      value: 'nearest',
      dir: 'ASC',
    },
  ];

  useEffect(() => {
    const concernedElement = document.querySelector('.click-text');
    document.addEventListener('mousedown', (event) => {
      if (!concernedElement.contains(event.target)) {
        setSortFilter(false);
      }
    });
  }, [sortFilter]);

  const {
    data: Res,
    refetch,
    isFetching,
    error,
  } = useGetAllUsersQuery({
    params: {
      perPage: listing.perPage,
      page: listing.page,
      query: listing.query && listing.query,
      sort: listing.sortedColumn.path,
      sortDir: listing.sortedColumn.order,
      ...filters,
    },
    id: compId,
  });

  const handleSearch = (evt) => {
    clearTimeout(timer);
    const query = evt.target.value;
    const newTimer = setTimeout(() => {
      setListing({ ...listing, page: 1, query: query });
    }, 2000);
    setSearchTimer(newTimer);
  };

  const handleSort = (sort) => {
    setListing((prevState) => ({
      ...prevState,
      sortedColumn: { ...sort },
      page: 1,
    }));
  };

  const handlePerPageChange = (perPage) => {
    setListing((prevState) => ({
      ...prevState,
      perPage: perPage.target.innerText,
      page: 1,
    }));
  };

  const handlePageChange = ({ selected }) => {
    setListing((prevState) => ({
      ...prevState,
      page: selected + 1,
    }));
  };

  const statusColor = [
    { status: 4, color: 'danger' },
    { status: 2, color: 'orange' },
    { status: 16, color: 'success' },
  ];

  const getTagColor = (statusId) => {
    const colour = statusColor?.find((i) => i.status === statusId)?.color;
    return colour;
  };

  const column = [
    // {
    //   id: 1,
    //   key: 'id',
    //   label: (
    //     <>
    //       <div className="d-flex">
    //         {/* <StyledCheckbox
    //           disabled={true}
    //           size={16}
    //           value={allCandSelect}
    //            onChange={() => onCheck(candRes)}
    //         /> */}
    //         <span className="mx-2">Id</span>
    //       </div>
    //     </>
    //   ),
    //   isChecked: true,
    //   alignBody: 'text-left align-middle',
    //   alignHeader: 'text-left align-middle cursor-pointer p-3',
    //   content: (data) => (
    //     <>
    //       <div className="d-flex p-2">
    //         {/* <StyledCheckbox
    //           size={15}
    //         disabled={true}
    //            value={candidateData?.checked}
    //            onChange={() => onCheck(candidateData)}
    //         /> */}
    //         <span className='ps-2'>{data?.id}</span>
    //       </div>
    //     </>
    //   ),
    // },
    {
      id: 2,
      key: 'firstName',
      path: 'firstName',
      label: 'Name',
      isChecked: true,
      alignBody: 'align-middle ps-4 py-3',
      alignHeader: 'align-middle cursor-pointer ps-4 py-3',
      content: (data) => (
        <>
          <h5 className="m-0 cut-text">{`${data?.firstName} ${data?.lastName}`}</h5>
          <span className="small cut-text">{data?.id}</span>
        </>
      ),
    },
    {
      id: 3,
      key: 'email',
      isChecked: true,
      path: 'email',
      label: 'Email',
      alignBody: 'text-center align-middle ps-4 py-3',
      alignHeader: 'text-center align-middle cursor-pointer ps-4 py-3',
    },
    {
      id: 4,
      key: 'Type',
      isChecked: true,
      label: 'Groups',
      alignBody: 'text-center align-middle ps-4 py-3',
      alignHeader: 'text-center align-middle cursor-pointer ps-4 py-3',
      content: (data) => (
        <>
          <div className="d-flex p-2 justify-content-center">
            {data?.accountType === 1 ? 'Standard' : 'Guest' || '-'}
          </div>
        </>
      ),
    },
    {
      id: 5,
      key: 'role',
      isChecked: true,
      label: 'Role',
      alignBody: 'text-center align-middle ps-4 py-3',
      alignHeader: 'text-center align-middle cursor-pointer ps-4 py-3',
      content: (data) => (
        <>
          <div className="d-flex p-2 justify-content-center">
            {data?.roles?.map((role, i) => (
              <>
                {role?.title} {i !== data?.roles?.length - 1 && ', '}
              </>
            ))}
          </div>
        </>
      ),
    },

    {
      id: 6,
      key: 'status',
      isChecked: true,
      label: 'Status',
      alignBody: 'text-center align-middle ps-4 py-3',
      alignHeader: 'text-center align-middle cursor-pointer ps-4 py-3',
      content: (data) => (
        <div className="d-flex p-2 justify-content-center">
          <StyledColoredTag
            className="rounded-pill mx-0"
            variant={getTagColor(data?.statusId)}
          >
            <OnlineIcon color={getTagColor(data?.statusId)} className="me-1" />{' '}
            {getStatusFriendly(data?.statusId)}
          </StyledColoredTag>
        </div>
      ),
    },
    {
      id: 7,
      key: 'actions',
      isChecked: true,
      label: 'Actions',
      alignBody: 'text-center align-middle text-nowrap ps-4 py-3',
      alignHeader: 'text-center align-middle cursor-pointer ps-4 py-3',
      content: (data) => (
        <div className="d-flex align-items-center justify-content-center">
          {data?.statusId !== 4 && (
            <Link
              variant="text"
              className="text-decoration-none"
              to={''}
              onClick={() => {
                setEditUser(true);
                setEditData(data);
              }}
            >
              <EditIcon />
            </Link>
          )}
          &nbsp;&nbsp;&nbsp;
          {data?.statusId !== 4 && (
            <div
              onClick={() =>
                deleteUser({ id: data?.id, companyId: data?.companyId }).then(
                  ({ data }) => {
                    toast.success(data?.message);
                    refetch();
                  },
                )
              }
              className="text-primary cursor-pointer"
            >
              <TrashIcon />
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <Portlet>
        <div className="portlet__body-bg">
          <PortletBody>
            <>
              <div className="flex-center mb-3">
                <div className="flex-grow-1">
                  <StyledIconInput
                    icon={<SearchIcon />}
                    className="mw-50"
                    $maxWidth="60%"
                    $marginLeft="3px"
                    onChange={handleSearch}
                    loading={isFetching}
                    placeholder="Search"
                  />
                </div>
                <div className="d-flex gap-2">
                  <StyledButton
                    className="feature-pending-new"
                    onClick={() => {
                      setAddUser(true);
                      setEditData(null);
                    }}
                  >
                    Add User
                  </StyledButton>
                  <StyledButton
                    className="px-2 py-1 feature-pending"
                    variant="icon"
                    onClick={() => ToggleFilterbar('filter')}
                    icon={<FilterIcon />}
                  >
                    Filter
                  </StyledButton>
                  <StyledButton
                    className="px-2 py-1 d-flex align-items-center feature-pending"
                    variant="icon"
                    icon={<SortIcon size="20" className="me-0" />}
                    id="sort"
                    onClick={() => ToggleFilterbar('sort')}
                  >
                    Sort
                  </StyledButton>
                </div>
              </div>
              {error || Res?.users?.length === 0 ? (
                <div className="d-flex justify-content-center align-items-center">
                  No Users Found ...
                </div>
              ) : (
                <>
                  {Res?.users?.length > 0 ? (
                    <UsersListingTable
                      users={Res?.users}
                      sortColumn={listing.sortedColumn}
                      onSort={handleSort}
                      filterTable={column}
                    />
                  ) : (
                    <TableSkeleton
                      cols={[
                        'Id',
                        'Name',
                        'Email',
                        'User Group',
                        'Role',
                        'Status',
                        'Actions',
                      ]}
                      rows={3}
                    />
                  )}
                  <PaginationPerPage
                    perPage={Res?.perPage || 0}
                    pageNo={Res?.page || 1}
                    onPerPageChange={handlePerPageChange}
                    totalCount={Res?.totalCount || 0}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </>
          </PortletBody>
        </div>
        <div className="m-0 position-relative d-flex rounded flex-column click-text">
          {sortFilter && (
            <StyledFilterBar
              variant={'sort'}
              className="bg-white p-3 align-items-center justify-content-center"
            >
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="mb-0">Sort By</h5>
              </div>
              {sortFilters.map((item, index) => {
                return (
                  <div
                    className="d-flex align-items-center py-2 px-2 "
                    key={`sort-${item.value}-index`}
                  >
                    <StyledRadioButton
                      value={filters.sort === item.value}
                      label={item.label}
                      className="fw-bold"
                      onChange={() => handleSortBy(item)}
                    />
                  </div>
                );
              })}
            </StyledFilterBar>
          )}
        </div>
        {/*
              <div className="portlet__body-bg position-relative bg-transparent click-filters">
                {isOpen && (
                  <StyledFilterBar variant="filter" className="my-1">
                    <div className="d-flex justify-content-end">
                      <div
                        className="cursor-pointer mt-3 me-3"
                        onClick={ToggleFilterbar}
                      >
                        <CrossIcon />
                      </div>
                    </div>

                    <div className="sd-header">
                      <h4 className="mb-0">Filters</h4>
                      {!isEqual(filters, initialFilters) && (
                        <h6
                          className="mb-0 cursor-pointer"
                          onClick={() => {
                            setFilters('');
                            setIsopen(false);
                          }}
                        >
                          Clear filters
                        </h6>
                      )}
                    </div>
                    <div className="sd-body">
                      <Formik
                        initialValues={{
                          ...filters,
                        }}
                        validationSchema={Yup.object().shape({
                          appDate: Yup.string(),
                        })}
                        onSubmit={handleSubmit}
                        innerRef={ref}
                      >
                        {({ handleSubmit, values, submitForm, errors }) => (
                          <Form onSubmit={handleSubmit}>
                            <UsersFilterForm submitForm={submitForm} />
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </StyledFilterBar>
                )}
              </div> */}
        {/* <div className="m-0 position-relative d-flex rounded flex-column click-text">
                {sortFilter && (
                  <StyledFilterBar
                    variant={'sort'}
                    className="bg-white p-3 align-items-center justify-content-center"
                  >
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h5 className="mb-0">Sort By</h5>
                    </div>
                    {sortFilters.map((item, index) => {
                      return (
                        <div
                          className="d-flex align-items-center py-2 px-2 "
                          key={`sort-${item.value}-index`}
                        >
                          <StyledRadioButton
                            value={filters.sort === item.value}
                            label={item.label}
                            className="fw-bold"
                            onChange={() => handleSortBy(item)}
                          />
                        </div>
                      );
                    })}
                  </StyledFilterBar>
                )}
              </div> */}

        {/* <div className="portlet__body-bg position-relative bg-transparent click-filters">
                {isOpen && (
                  <StyledFilterBar variant="filter" className="my-1">
                    <div className="d-flex justify-content-end">
                      <div
                        className="cursor-pointer mt-3 me-3"
                        onClick={ToggleFilterbar}
                      >
                        <CrossIcon />
                      </div>
                    </div>

                    <div className="sd-header">
                      <h4 className="mb-0">Filters</h4>
                      {!isEqual(filters, initialFilters) && (
                        <h6
                          className="mb-0 cursor-pointer"
                          onClick={() => {
                            setFilters('');
                            setIsopen(false);
                          }}
                        >
                          Clear filters
                        </h6>
                      )}
                    </div>
                    <div className="sd-body">
                      <Formik
                        initialValues={{
                          ...filters,
                        }}
                        validationSchema={Yup.object().shape({
                          appDate: Yup.string(),
                        })}
                        onSubmit={handleSubmit}
                        innerRef={ref}
                      >
                        {({ handleSubmit, values, submitForm, errors }) => (
                          <Form onSubmit={handleSubmit}>
                            <UsersFilterForm submitForm={submitForm} />
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </StyledFilterBar>
                )}
              </div> */}
      </Portlet>
      <AddUserModal
        id={compId}
        editData={editData}
        refetch={refetch}
        show={addUser || editUser}
        onHide={() => {
          setAddUser(false);
          setEditUser(false);
        }}
        title={editUser ? 'Edit User' : 'Add User'}
      />
    </div>
  );
};

export default UserListingContainer;
