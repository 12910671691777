export const STATUSES = {
  NULL: 0,

  PENDING: 1,
  NEW: 1,
  LIVE: 2,
  DRAFT: 6,
  ARCHIVED: 7,
  DELETE: 4,
  SUSPENDED: 3,

  // For account
  ACTIVE: 16,
  INACTIVE: 17,

  SCHEDULED: 101,
  PARTIALLY_LIVE: 102,

  // For Job Posting
  REVIEW: 26,
  BEING_POSTED: 27,
  POSTED: 28,
  CANCELED: 36,
  FAILED: 19,

  // For Job
  CLOSED: 21,

  JOB_TEMPLATE: 52,
};

export const TEMPLATES = {
  NULL: 0,
  draft: 6,
  standard: 7,
  custom: 8,
};
export const MESSAGE_TYPES = {
  SMS: 1,
  EMAIL: 2,
};

export const FORM_TYPES = {
  CANDIDATE: 1,
  INTERNAL: 4,
};
export const PRODUCT_TYPE = {
  DETAILS: 0,
  JOB_BOARDS: 1,
  BUNDLES: 1,
};
export const JOB_COPY_WRITING = {
  REQUESTED: 'REQUESTED',
  IN_PROGRESS: 'IN_PROGRESS',
  AWAITING_FEEDBACK: 'AWAITING_FEEDBACK',
  APPROVED: 'APPROVED',
  CANCELLED: 'CANCELLED',
};
