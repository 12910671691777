import React, { Fragment, useEffect, useState } from 'react';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import PortletBody from '../../../app/components/portlet/PortletBody';
import CVLibraryIcon from '../../../app/components/svgIcons/CVLibraryIcon';
import GlobeIcon from '../../../app/components/svgIcons/GlobeIcon';
import CalenderIcon from '../../../app/components/svgIcons/CalenderIcon';
import ClipboardCheckIcon from '../../../app/components/svgIcons/ClipboardCheckIcon';
import MonsterImgIcon from '../../../app/components/svgIcons/MonsterImgIcon';
import ReedImgIcon from '../../../app/components/svgIcons/ReedImgIcon';
import { useAdvertRePostingMutation } from '../CampainApiSlice';
import { advertKeys, jobBoardAccessKe } from '../CampaignsUtils';
import { StyledLabel } from '../../../assets/styledComponents/Global';
import theme from '../../../utils/themes';
import CandidatesFillIcon from '../../../app/components/svgIcons/CandidatesFillIcon';
import StyledColoredTag from '../../../app/components/styledComponents/StyledColoredTag';
import { toast } from 'react-toastify';
import moment from 'moment';
import styled from 'styled-components';
import StyledToolTip from '../../../app/components/styledComponents/StyledToolTip';
import MarginIcon from '../../../app/components/svgIcons/MarginIcon';
import {
  useManualAdvertiseMutation,
  useMarkJobBoardAdvertiseMutation,
} from '../../Job/jobApiSlice';
import TotalJobIcon from '../../../app/components/svgIcons/TotalJobIcon';
import GumtreeIcon from '../../../app/components/svgIcons/GumtreeIcon';

const JobBoardSummary = ({
  jobBoardsSummaryDetails,
  refetchSummaryDetail,
  postingDetails,
  refId,
  values,
  handleSubmit,
  setFieldValue,
  jobBoardKeys,
  postingId,
  clickNext,
  campaginRefetch,
  postingDetailsLoading
}) => {
  const [advertRePosting, { isLoading: advertRepostingLoading }] =
    useAdvertRePostingMutation();
  const [manualPostingAdvertise, { isLoading: manualPostLoading }] =
    useManualAdvertiseMutation();
  const [markJobBoardPosted, { isLoading: markJobboardPostingLoading }] =
    useMarkJobBoardAdvertiseMutation();
  const [currentJobBoardId, setCurrentJobBoardId] = useState(false);
  const jobBoardsSummary = jobBoardsSummaryDetails;
  const repostCall = (values) => {
    setCurrentJobBoardId(values?.id);
    const payload = {
      advertRefId: refId,
      productId:
        postingDetails?.campaignGeneralInfo?.jobPostings[0]?.product?.id,
      advertJBIds: [values?.id],
      companyId: postingDetails?.campaignGeneralInfo?.jobHiringPlan?.companyId,
    };
    advertRePosting({ payload })
      .unwrap()
      .then((res) => {
        refetchSummaryDetail();
        toast.success(res?.message || 'Reposted Successfully!');
        setCurrentJobBoardId(false);
      });
  };

  const onMarkAdvertise = async (postingId, jobBoardId) => {
    const { data } = await markJobBoardPosted({
      postingId,
      jobBoardId,
    });
    if (data?.message) {
      toast.success(data?.message || 'Selected jobboard mark as posted');
      campaginRefetch();
      setCurrentJobBoardId(false);
    }
  };

  const formatDateTime = (inputDate) => {
    const date = moment(inputDate);
    const formattedDate = moment(date).isValid()
      ? date.format('Do MMM YYYY')
      : '-';
    return formattedDate;
  };

  const formatTime = (inputDate) => {
    const date = moment(inputDate);
    const formattedDate = moment(date).isValid() ? date.format(', HH:mm') : '-';
    return formattedDate;
  };
  useEffect(() => {
    if (jobBoardsSummary?.length === 0) {
      values['summary'] = true;
      if (!clickNext) handleSubmit(values);
    }
  }, [!jobBoardsSummary?.length]);

  const capitalizeText = (text) => {
    if (typeof text === 'string') {
      return text
        ?.split('_')
        ?.map(
          (word) =>
            word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase(),
        )
        ?.join(' ');
    } else {
      return '';
    }
  };

  const onManualAdvertise = async (jobId) => {
    const { data } = await manualPostingAdvertise(jobId);
    setCurrentJobBoardId(false);
    if (data?.broadbeanUrl) {
      window.open(data?.broadbeanUrl || '', '_blank');
    } else if (data?.statusId)
      toast.success(data?.message || 'Job manual advertised successfully');
    // campaginRefetch();
  };

  return (
    <>
      {jobBoardsSummary && (
        <PortletBody className="mt-3 w-100 p-5">
          <h3 className="pb-2">Job Boards</h3>
          {jobBoardsSummary.length
            ? jobBoardsSummary?.map((jobBoard, index) => (
                <div
                  className="border border-muted rounded mt-2 p-2"
                  key={`jobBoard-details-${index}`}
                >
                  <div className="d-flex justify-content-between align-items-center p-2 p-lg-3 template-item bg-white rounded mt-1">
                    <div className="text-start d-flex">
                      <div className="px-2">
                        {jobBoard?.name === jobBoardAccessKe?.CV_LIBRARY ? (
                          <CVLibraryIcon size={48} />
                        ) : jobBoard?.name === jobBoardAccessKe?.REED ? (
                          <ReedImgIcon size={48} />
                        ) : jobBoard?.name === jobBoardAccessKe?.MONSTOR ? (
                          <MonsterImgIcon size={48} />
                        ) : jobBoard?.name === jobBoardAccessKe?.TOTAL_JOBS ? (
                          <TotalJobIcon size={48} />
                        ) : jobBoard?.name === jobBoardAccessKe?.GUM_TREE ? (
                          <GumtreeIcon size={48} />
                        ) : (
                          <StyledBox>
                            {jobBoard?.name && jobBoard.name[0]}
                          </StyledBox>
                        )}
                      </div>
                      <div className="col-12 fw-bold">
                        <div className="mb-0 d-flex">
                          <StyledLabel weight={500} size="16px">
                            {capitalizeText(jobBoard?.name) || '-'}
                          </StyledLabel>
                          <StyledColoredTag
                            color={
                              jobBoard?.status === advertKeys?.ACTIVE
                                ? theme.green
                                : theme.darkRed
                            }
                            backgroundColor={
                              jobBoard?.status === advertKeys?.ACTIVE
                                ? theme.skygreen
                                : theme.evaluationBannerColor
                            }
                          >
                            {jobBoard?.status === advertKeys?.ACTIVE
                              ? 'Published'
                              : 'Not Published'}
                          </StyledColoredTag>
                        </div>
                        <div className="col-12 d-flex mt-1">
                          {jobBoard?.url && (
                            <Fragment>
                              <div className="d-flex align-items-center">
                                <GlobeIcon className="ms-2" />
                                <a
                                  href={`${jobBoard?.name === jobBoardAccessKe?.TOTAL_JOBS ? `https://${jobBoard?.url}` : jobBoard?.url}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ verticalAlign: 'middle' }}
                                >
                                  <StyledTextTruncate className="text-truncate">
                                    <StyledLabel
                                      weight={500}
                                      color={theme.textColor}
                                      className="ms-2 cursor-pointer"
                                    >
                                      {' '}
                                      {jobBoard?.url}{' '}
                                    </StyledLabel>
                                  </StyledTextTruncate>
                                </a>
                              </div>
                              <span className="dot mx-2" />
                            </Fragment>
                          )}
                          {jobBoard?.candidates && (
                            <Fragment>
                              <span>
                                <CandidatesFillIcon size={20} className="" />{' '}
                                <StyledLabel
                                  weight={500}
                                  color={theme.textColor}
                                >
                                  {' '}
                                  {`${jobBoard?.candidates} Candidates`}{' '}
                                </StyledLabel>
                              </span>
                              <span className="dot mx-2" />
                            </Fragment>
                          )}
                          {jobBoard?.status !== advertKeys?.ACTIVE && (
                            <span className="d-flex">
                              <MarginIcon className="" />
                              <StyledLabel
                                weight={500}
                                color={theme.textColor}
                                className="mx-1"
                              >
                                {' '}
                                Service Down{' '}
                              </StyledLabel>
                            </span>
                          )}
                          {jobBoard?.requestDate && (
                            <Fragment>
                              <span className="d-flex">
                                <StyledToolTip
                                  title="Publish Request Sent  on"
                                  childrenClass={''}
                                >
                                  <CalenderIcon
                                    className=""
                                    style={{ verticalAlign: 'sub' }}
                                  />
                                </StyledToolTip>
                                <StyledLabel
                                  weight={500}
                                  color={theme.textColor}
                                  className="mx-1"
                                >
                                  {' '}
                                  {formatDateTime(jobBoard?.requestDate)}{' '}
                                </StyledLabel>
                                <StyledLabel
                                  weight={500}
                                  color={theme.textColor}
                                  className="mx-"
                                >
                                  <StyledToolTip
                                    title="Published on"
                                    childrenClass={''}
                                  >
                                    {formatTime(jobBoard?.requestDate)}
                                  </StyledToolTip>{' '}
                                </StyledLabel>
                              </span>
                              <span className="dot mx-2" />
                            </Fragment>
                          )}
                          {jobBoard?.postedOn &&
                            jobBoard?.status !== advertKeys?.ACTIVE && (
                              <span className="d-flex">
                                <StyledToolTip
                                  title="Posted on"
                                  childrenClass={''}
                                >
                                  <ClipboardCheckIcon
                                    className="ms-2"
                                    style={{ verticalAlign: 'text-top' }}
                                  />
                                </StyledToolTip>
                                <StyledLabel
                                  weight={500}
                                  color={theme.textColor}
                                  className="mx-1"
                                >
                                  {' '}
                                  {formatDateTime(jobBoard?.postedOn)}{' '}
                                </StyledLabel>
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                    {jobBoard?.status !== advertKeys?.ACTIVE && (
                      <div className="d-flex align-items-center">
                        <StyledButton
                          variant="white"
                          className=""
                          onClick={() => repostCall(jobBoard)}
                          disabled={advertRepostingLoading || manualPostLoading}
                          loading={
                            currentJobBoardId === jobBoard?.id &&
                            advertRepostingLoading
                          }
                        >
                          Repost
                        </StyledButton>
                      </div>
                    )}
                  </div>
                </div>
              ))
            : null}
          {jobBoardKeys.length &&
            jobBoardKeys?.map(
              (jobBoard, jbIndex) =>
                jobBoard.posterAccessKey === 'BB' && (
                  <div
                    className="border border-muted rounded mt-2 p-2"
                    key={`jobBoard-Keys-${jbIndex}`}
                  >
                    <div className="d-flex justify-content-between align-items-center p-2 p-lg-3 template-item bg-white rounded mt-1">
                      <div className="text-start d-flex">
                        <div className="px-2">
                          <StyledBox>
                            {jobBoard?.jobBoard?.broadbeanShortcode &&
                              jobBoard?.jobBoard?.broadbeanShortcode[0]}
                          </StyledBox>
                        </div>
                        <div className="col-12 fw-bold">
                          <div className="mb-0 d-flex">
                            <StyledLabel weight={500} size="16px">
                              {capitalizeText(
                                jobBoard?.jobBoard?.broadbeanShortcode,
                              ) || '-'}
                            </StyledLabel>
                          </div>
                          <div className="col-12 d-flex mt-1">
                            <Fragment>
                              <span>
                                <StyledLabel
                                  weight={500}
                                  color={theme.textColor}
                                  className="ms-2 cursor-pointer"
                                >
                                  {' '}
                                  Broadbean
                                </StyledLabel>
                              </span>
                              <span className="dot mx-2" />
                            </Fragment>

                            {jobBoard?.applicationCount && (
                              <Fragment className="d-flex align-items-center">
                                <span>
                                  <CandidatesFillIcon size={20} className="" />{' '}
                                  <StyledLabel
                                    weight={500}
                                    color={theme.textColor}
                                  >
                                    {' '}
                                    {`${jobBoard?.applicationCount} Candidates`}{' '}
                                  </StyledLabel>
                                </span>
                                <span className="dot mx-2" />
                              </Fragment>
                            )}

                            {jobBoard?.requestDate && (
                              <Fragment>
                                <span className="d-flex">
                                  <StyledToolTip
                                    title="RequestDate on"
                                    childrenClass={''}
                                  >
                                    <CalenderIcon
                                      className=""
                                      style={{ verticalAlign: 'sub' }}
                                    />
                                  </StyledToolTip>
                                  <StyledLabel
                                    weight={500}
                                    color={theme.textColor}
                                    className="mx-1"
                                  >
                                    {' '}
                                    {formatDateTime(jobBoard?.requestDate)}{' '}
                                  </StyledLabel>
                                </span>
                                <span className="dot mx-2" />
                              </Fragment>
                            )}
                            {jobBoard?.postedOn &&
                              jobBoard?.status !== advertKeys?.ACTIVE && (
                                <span className="d-flex">
                                  <StyledToolTip
                                    title="Posted on"
                                    childrenClass={''}
                                  >
                                    <ClipboardCheckIcon
                                      className="ms-2"
                                      style={{ verticalAlign: 'text-top' }}
                                    />
                                  </StyledToolTip>
                                  <StyledLabel
                                    weight={500}
                                    color={theme.textColor}
                                    className="mx-1"
                                  >
                                    {' '}
                                    {formatDateTime(jobBoard?.postedOn)}{' '}
                                  </StyledLabel>
                                </span>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="d-flex align-items-center">
                        {jobBoard?.jobBoard?.isPosted === 0 && (
                          <StyledButton
                            variant="primary"
                            className="me-3"
                            onClick={() => {
                              setCurrentJobBoardId(jobBoard?.id);
                              onMarkAdvertise(
                                postingDetails?.campaignGeneralInfo
                                  ?.jobPostings[0]?.id,
                                jobBoard?.jobBoard?.id,
                              );
                            }}
                            loading={
                              currentJobBoardId === jobBoard?.id &&
                              markJobboardPostingLoading
                            }
                            disabled={
                              manualPostLoading ||
                              advertRepostingLoading ||
                              markJobboardPostingLoading ||
                              postingDetailsLoading
                            }
                          >
                            Mark as advertised
                          </StyledButton>
                        )}
                        {jobBoard?.jobBoard?.isPosted === 1 ? (
                          <StyledLabel
                            weight={500}
                            color={theme.textColor}
                            className="mx-1"
                          >
                            {' '}
                            Posted{' '}
                          </StyledLabel>
                        ) : (
                          <StyledButton
                            variant="white"
                            className=""
                            onClick={() => {
                              setCurrentJobBoardId(jobBoard?.id);
                              onManualAdvertise(
                                postingDetails?.campaignGeneralInfo
                                  ?.jobPostings[0]?.id,
                              );
                            }}
                            loading={
                              currentJobBoardId === jobBoard?.id &&
                              manualPostLoading
                            }
                            disabled={
                              manualPostLoading ||
                              advertRepostingLoading ||
                              markJobboardPostingLoading ||
                              postingDetailsLoading
                            }
                          >
                            Publish
                          </StyledButton>
                        )}
                      </div>
                    </div>
                  </div>
                ),
            )}
        </PortletBody>
      )}
    </>
  );
};

const StyledBox = styled.div`
  padding: 2px;
  border-radius: 6px;
  background: ${theme.primaryLight};
  width: 50px;
  height: 50px;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
`;

const StyledTextTruncate = styled.div`
  max-width: 356px;
`;

export default JobBoardSummary;
